.templates {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.template {
    margin-right: 0.2em;
    margin-bottom: 0.5em;
    cursor: pointer;
}

img {
    width: 100%;
}

.template_parent {
    flex-basis: 50%;
    min-height: 15vh;
}

.gradient_thumbnail {
    height: 10vh;
}

.templates p {
    font-size: 18px;
    width: 70%;
}