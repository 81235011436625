.designs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.design {
    border-radius: 0.2em;
    margin-right: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    cursor: pointer;
}

#nodisplay {
    display: none;
}

.design img {
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    flex-basis: 25%;
    cursor: pointer;
}


.align_end {
    margin-left: auto;
}

.skeleton {
    width: 40vw;
    height: 45vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-right: 1vh;
}

#designs canvas {
    width: 10vw;
    height: 20vh;
    flex-basis: 15%;
    margin-top: 5vh;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


.animated_gradient {
    background-size: 400% 400%;
    animation: gradient 25s ease infinite;
}

.gradient_preview {
    height: 10em;
    width: 26em;
    margin-top: 5vh;
    box-sizing: border-box;
}

#designs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.design h2 {
    text-align: right;
}

.visible {
    display: contents;
}

.designsEmptyState {
    text-align: center;
}

.designsEmptyState a {
    margin: 1vh;
    margin-top: 2vh;
}

.svg_container {
    flex-basis: 30%;
}