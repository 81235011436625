.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
}

.logo {
    display: inline-block;
}
  
.navLinks {
    list-style: none;
    display: flex;
}
  
.nav-item a {
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
    color: white;
}
  
.nav-item:hover {
    background-color: white;
}
  
.nav-item:hover a {
    /* color: darkgreen; */
}

.containerNav {
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    height: 65px;
    left: 0;
}

.headerNav {
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 3;
    align-items: center; 
    top: 0;
    width: 99%;
    height: 65px;
    left: 0;
    background-color: rgba(255, 255, 255, 1); 
}

.dashboardLogo {
    margin-left: 2vh;
}

.darkNav {
    background-color: #1A202C;
}
  
.leftnav_dashboard {
    flex-grow: 1; 
    margin-left: 2.45vw;
}
  
.logout {
    margin-right: 2vw;
}

.logout:hover {
    color: black;
}
  
.headerNav a {
    padding: 1em;
}

.rightnav {
    margin: 0.4em;
}