.sidebar_content {
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: auto;
  grid-template-areas: "sidebar_left sidebar_right";
  height: 100%;
}

.square {
  margin: 0.3em;
  cursor: pointer;
  width : "10vh";
  height: "10vh";
  border-radius: 0.6em;
}

.sidebar, h1 {
  z-index: 0;
  font-size: 2vw;
}

.sidebar h2 {
  font-size: 1.2vw;
}

.sidebar h3 {
  font-size: 1vw;
}

.sidebar_left_bar li a {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.sidebar_left_bar {
  margin-top: 18vh;
}

.sidebar {
  height: 100%;
  width: 30vw;
  z-index: 2;
  top: 0;
  left: 0;
  position: fixed;
  /* background-color: rgb(0, 0, 0); */
  overflow-x: hidden;
  /* color: rgb(105, 105, 105); */
  display: block;
  background-color: white;
}

.sidebar h1 {
    font-family: "Inter", sans-serif;
    text-align: center;
    width: 100%;
}

.sidebar_left_bar {
  grid-area: sidebar_left;
  position: fixed;
  height: 100%;
  margin-left: 0;
  /* background-color: rgb(0, 0, 0); */
  font-size: 0.93vw;
  z-index: 9999;
}

.sidebar_right_bar {
  grid-area: sidebar_right;
  margin-left: 0;
  position: sticky;
  top: 0;
  height: 100vh;
  padding-top: 10vh;
  /* background-color: rgb(27, 27, 27); */
}

a {
  text-decoration: none;
}

li:hover{
  cursor: pointer;
  color: rgb(84, 3, 190);
}

.sidebar_left_bar li {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8vh;
  font-family: "Lato", sans-serif;
  padding-top: 3vh;
  padding-right: 1vh;
  text-align: center;
}

.logo_sidebar {
  padding-top: 0vh;
  color: black;
}

.sidebar_content {
  height: 90%;
}

ul {
    margin: 0;
}

 /* width */
.sidebar ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.sidebar ::-webkit-scrollbar-track {
  background: transparent
}
.active_btn {
  color: rgb(84, 3, 190);
}

/* Handle */
.sidebar ::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
.sidebar ::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

.inner_pane {
  margin-left: 2vh;
}

.gradients {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.gradient {
    margin: 0.3em;
    cursor: pointer;
    width : "10vh";
    height: "10vh";
    border-radius: 0.1em;
}

.red {
  color: red;
}

.green {
  color: green;
}