.home {
    font-family: "Inter", sans-serif;
}

.landing_hero {
    display: grid;
	grid-template-columns: 45% 55%;
	grid-template-rows: auto;
    grid-template-areas: "hero-left hero-right";
    margin: 15vh;
}

.landing_hero h1 {
    margin-bottom: 0em;
}

.landing_hero_lhs {
    grid-area: hero-left;
    justify-content: left;
    width: 90%;
}

.landing_hero_left p {
    font-size: 0.5em;
    color: gray;
}

.landing_hero_rhs {
    grid-area: hero-right;
}

.landing_hero_lhs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: vertical;
}


#svg_home_animation{
    position: absolute;
    left: 0;
    top: 0;
    height: 98vh;
    width: 98vw;
}

#container {
    height: 98vh;
    width: 98vw;
    position: relative;
}

#text {
    z-index: 100;
    position: absolute;
    color: black;
    left: 10%;
    top: 15%;
}

.landing_hero_left {
    width: 60%;
    font-size: 2.8em;
}

.landing_hero_left h1 {
    margin-bottom: 0.4em;
    line-height: 1.2em;
}

.hero {
    display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto;
    grid-template-areas: "hero_lhs hero_rhs";
}

.hero_rhs {
    grid-area: hero_rhs;
}

.hero_lhs {
    grid-area: hero_lhs;
    margin-left: auto;
    margin-right: auto;
    min-height: 60vh;
    text-align: left;
    width: 60%;
}

.hero_lhs h1 {
    font-size: 2em;
}

.hero_lhs p {
    font-size: 1.2em;
    color: #3f3f44;
}

.centre {
    text-align: center;
}

