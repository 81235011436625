.heart {
    display: none;

}
  
   @keyframes heartPulse {
    0% {transform: scale(1);}
    25% {transform: scale(.97);}
    35% {transform: scale(.9);}
    45% {transform: scale(1.1);}
    55% {transform: scale(.901);}
    65% {transform: scale(1.1);}
    75% {transform: scale(1.031);}
    100% {transform: scale(1);}
  }
  
  .heart_anim { 
    animation-name: heartPulse; 
    animation-iteration-count: 1;
    animation-duration: 2s;
  }

.visible_icon {
    display: inline;
    position:absolute;
    bottom: 5%;
    right: 5%;
    overflow: visible;
}

.browse_container {

        &:hover {
            
            .heart {
            display: inline;
            position:absolute;
            bottom: 5%;
            right: 5%;
            overflow: visible;

            &:hover {
                stroke: darkred;

            }
        }
    }


}