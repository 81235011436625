.element h3 {
    text-align: left;
}

.element h1 {
    font-size: 2vw;
}

ion-icon {
    cursor: pointer;
}

.slider {
    width: 90%;
}

.hidden {
    display: none;
}

.element p {
    font-size: 0.5em;
    text-align: center;
}

.slider {
    margin-top: 3vh;
    margin-bottom: 3vh;
}