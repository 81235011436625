section h2 {
    text-align: left;
}

.element_svg {
    cursor: pointer;
}

.hidden {
    display:none; 
}

.elements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.element_container {
    flex-basis: 30%;
    cursor: pointer;
}