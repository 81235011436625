nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
}

nav ul li {
    list-style: none;
    margin-right: 3em;
}

.logo {
    flex-grow: 1;
}

nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
  
.logo {
    flex-grow: 1;
    /* margin-left: 3em; */
    text-decoration: none;
    /* color: white; */
}

.logo:hover {
    color: silver;
    cursor: pointer;
}

.home_nav {
    padding-top: 1vh;
}