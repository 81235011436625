.left_margin {
    margin-left: 30vw;
}

.svg_illustration {
    width: 70vw;
    height: 93vh;
    margin-top: 7vh;
}

#gradient {
    width: 70vw;
    height: 100vh;
    background: linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(286, 100%, 69%, 1) 100%);  
}

#svg_overlay_container {
    position: relative;
    display: inline-block; 
}

#svg_overlay_container svg {
    width: 100%;
    height: 100%;
}

#svg_overlay_container #svg_overlay_illustration {
    position: absolute;
    top: 0;
    left: 0;
} 
#particles {
    height: 100vh;
    width: 100vh;
}
#svg_overlay_container .particles {
    display: block;
    max-width: 100%;
    height: auto;
}

#triangles {
    background: linear-gradient(to bottom right, #ff0055 , #220055);
    position: fixed;
    top: 0;
    right: 0;
    width: 70vw;
}

/* #particles {
    background: linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%); 
    background: linear-gradient(90deg, hsla(238, 100%, 71%, 1) 0%, hsla(295, 100%, 84%, 1) 100%);
} */

.editor_canvas {
    /* max-width: 100%;
    max-height: 100%; */
    margin-top: 20vh;
}

.upload_template {
    position: fixed;
    top: 100;
    left: 100;
    color: black;
}

.svg_select_boundingRect{
    stroke-width:1;
    fill:gray;
    stroke-dasharray:10 10;
    stroke:black;
    stroke-opacity:0.8;
    fill-opacity:0.1;
    pointer-events: none; /* This ons is needed if you want to deselect or drag the shape*/
}

.svg_select_points_lt{
    cursor: nw-resize;
}
.svg_select_points_rt{
    cursor: ne-resize;
    /* stroke:rgb(153, 0, 0);
    fill: rgb(153, 0, 0); */
}

.svg_select_points_rb{
    cursor: se-resize;
}

.svg_select_points_lb{
    cursor: sw-resize;
}
.svg_select_points_t{
    cursor: n-resize;
}
.svg_select_points_r{
    cursor: e-resize;
}
.svg_select_points_b{
    cursor: s-resize;
}
.svg_select_points_l{
    cursor: w-resize;
}

  

  div.wave{
    width: 70vw;
    height: 30vh;
    bottom: 0;
    position: absolute;
    z-index: 99999;
  }
  
  .wave svg{
    position: absolute;
    width: 70vw;
    bottom: 0;
    left: 0;
  }
  
  #container {
      position: relative;
  }

  .nodisplay {
      display: none;
  }

  .element {
    background: 
      radial-gradient(
        circle at top left,
        rgba(255, 255, 255, 0.5), 
        transparent 100px
      ),
      radial-gradient(
        circle at top right,
        rgba(255, 255, 255, 0.5), 
        transparent 100px
      ),
      radial-gradient(
        at bottom left,
        rgba(255, 0, 255, 0.5), 
        transparent 400px
      ),
      radial-gradient(
        at bottom right,
        rgba(255, 100, 100, 0.5), 
        transparent 400px
      );
  }