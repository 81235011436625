.btns button {
    border: none;
    padding: 0.5em 1em 0.5em 1em;
    margin: 0.5em;
    color: black;
    font-size: 0.5em;
    cursor: pointer;
}

.underline {
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
}

.rounded-btn {
	border-radius: 1em;
}

.gradient-text {
  background: -webkit-linear-gradient(#e43e12, #123321);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;	
}

.med-rounded-btn {
	border-radius: 0.4em;
}

.underline:hover {
    background-size: 100% 88%;
}

.imgs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.img {
    background-image: url(https://images.unsplash.com/photo-1547304206-e2e68bae3729?ixlib=rb-1.2.1&q=80&fm=png&crop=entropy&cs=tinysrgb&w=200&fit=max&ixid=eyJhcHBfaWQiOjEyMDkwNH0.png),
    linear-gradient(to right, red, blue);
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    height: 30vh;
    width: 20vw;
}