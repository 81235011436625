.editor_header_nav {
    z-index: 3; 
    position: fixed;
    background-color: white;
    justify-content: flex-end;
    padding: 0.6em;
    top: 0;
    left: 0;
}

.editor_header_nav li {
    margin-left: 1em;
}

.editor_header_nav .icon {
    cursor: pointer;
}

.hidden {
    display: none;
}

.leftnav {
    flex-grow: 1; 
    margin-left: 2vh;
}

.lightThemeNav {
    background-color: white;
}