.dashboard_sidebar {
    height: 100%;
    width: 8vw;
    z-index: 1;
    top: 0;
    left: 0;
    position: fixed;
    overflow-x: hidden;
    display: block;
    margin-top: 21vh;

}

.dashboard_sidebar h1 {
    font-family: "Inter", sans-serif;
    color: black;
    font-size: 0.9vw;
    text-align: center;
    width: 100%;
}

.dashboard_sidebar li a {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10vh;
    font-size: 0.97vw;
}

.dashboard_sidebar li a:hover {
    color: rgb(111, 0, 255);
}

.active {
    color: rgb(111, 0, 255);;
}