@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;800&display=swap');

.App {
    max-width: 100vw;
    font-family: "Inter", sans-serif;
    font-size: 18px;
}

.App h1, .App h2, .App h3 {
    font-weight: 800;
}

.site_nav {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.05vw;
}

@media screen and (max-width: 40em) {
    .site_nav {
        font-size: 3em;

    }

    .landing {
        width: 100%;
        padding-top: 25vh;
        padding-left: 1vh;
        padding-right: 1vh;
        text-align: center;
    }

    .gradient_text {
        font-size: 2.4em;
    }
}

.drawer {
  z-index: 99999999999999;
}

.landing_container {

}

@media screen and (min-width: 40em) {
    .landing {
        width: 80%;

        height: 95vh;
        padding: 15vh;
        margin-left: auto;
        margin-right: auto;
    }
    

}

@media screen and (min-width: 45em) {
    .landing {
        width: 100%;
        display: grid;
        grid-template-columns: 58% 42%;
        grid-template-rows: auto;
        grid-template-areas: "middle-left middle-right";
        height: 100vh;
        padding: 25vh;
    }
    
    .landing_left {
        grid-area: "middle-left";
    }
    
    .landing_right {
        grid-area: "middle-right";
    }
}

@media screen and (min-width: 48em) {
    .landing {
        width: 100%;
        display: grid;
        grid-template-columns: 58% 42%;
        grid-template-rows: auto;
        grid-template-areas: "middle-left middle-right";
        height: 95vh;
        padding: 25vh;
    }
    
    .landing_left {
        grid-area: "middle-left";
    }
    
    .landing_right {
        grid-area: "middle-right";
    }
}


.heading h2 {
    flex-grow: 1;
    width: 80%;
}

.button {
    padding: 0.1em;
    border-radius: 0.2em;
    font-size: 0.9em;
}

.pointer {
  cursor: pointer;
}

.heading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: center;
}

.heading h2 {
    flex-grow: 1;
    width: 80%;
}

.preview_gradient {
  background: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.gradient_two {
  background: linear-gradient(90deg, #00DBDE 0%,  #FC00FF  100%);
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.icon {
    cursor: pointer;
}

.error_msg {
    color: darkred;
}

#particles canvas{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

/* Additional styles  */

:focus,
button:focus,
.btn:focus,
.btn-sm:focus {
    outline: 2px solid rgba(#0070F4, 0.5);
}

.blur {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

.animation-delay-500 {
    animation-delay: 500ms !important;
}

.animation-delay-1000 {
    animation-delay: 1000ms !important;
}

.translate-z-0 {
    transform: translateZ(0);
}

[data-aos="zoom-y-out"] {
  transform: scaleX(1.03);
  opacity: 0;
  transition-property: transform, opacity;
}

.test {
  margin: 2em;
  font-size: 1.7em;
  font-family: "Inter", sans-serif;
  width: 100%;
}

/* h1, h2, h3, h4, h5, h6, p, span {
  font-family: "Inter", sans-serif;
  color: rgb(226,226, 226)
} */

.test img {
  border-radius: 0.8em;
  width: 100%;
}

.lightTheme {
  color: black;
  background-color: white;
}

.imageGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
}

.imageGrid2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.imageGridItem {
  display: flex;
  flex-basis: 27%;
}

.imageGrid2 img, .imageCard {
  flex-basis: 27%;
  justify-content: space-between;
}

.animatedBackground {
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  width: 100vw;
  height: 100vh;
  font-family: "Inter", sans-serif;
  font-weight: 800;
}

.animatedBackground .outer_container {
  padding: 15vh;
}

.animatedBackground .inner_container {
  background-color: white;
  padding: 15vh;
  font-size: 3em;
  padding: 15vh;
  border-radius: 0.5em;
  
} 

.relative {
  position: relative;
}

.cont {
  width: 100vw;
  height: 100vh;
}

.pricing {
  margin-top: 10vh;
  padding-top: 10vh;
}

.msg {
  width: 80%;
}

#svg_overlay_container {
  position: relative;
  display: inline-block; 
}

#svg_overlay_container #svg_overlay_illustration {
  position: absolute;
  top: 0;
  left: 0;
} 

#svg_overlay_container .particles {
  display: block;
  max-width: 100%;
  height: auto;
}

.animatedBackground p {
  font-weight: 400;
  font-size: 0.8em;
}

.imageInfo {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas: "image_info_lhs image_info_rhs";
  /* color: whitesmoke; */

}

.imageInfoRight {
  grid-area: imageinfo_rhs;
  padding: 10vh;
}

.imageInfoLeft {
  grid-area: imageinfo_lhs;
}

.fullHeight {
  height: 100vh;
  width: 100vw; 
}

.lightbox {
	position: fixed;
	z-index: 1000;
	width: 95vw;
	height: 100vh;
	top: 0;
	left: 0;
}

.lightbox img {
  max-width: 100%;
  max-height: 100%;
  display:block;
  margin:auto;
} 

.lightbox_container {
  display: block;
  margin: auto;
  max-height: 100vh;
}

.grid-item, .grid-sizer {  width: 30%; }

.grid-item {
  margin-bottom: 10px;
}


   .typed-cursor{
    opacity: 0;
    animation: typedjsBlink 0.7s infinite;
    -webkit-animation: typedjsBlink 0.7s infinite;
    animation: typedjsBlink 0.7s infinite;
  }
  @keyframes typedjsBlink{
    50% { opacity: 0.0; }
  }
  @-webkit-keyframes typedjsBlink{
    0% { opacity: 0; }
    50% { opacity: 0.0; }
    100% { opacity: 0; }
  }
  .typed-fade-out{
    opacity: 0;
    transition: opacity .25s;
    -webkit-animation: 0;
    animation: 0;
}

.text_landing {
  height: 1.4em;
  padding-top: 10px;
  padding-bottom: 10px;
}

#wave {
  z-index: -1;
}